
import Vue from "vue";
import { Rq } from "@/lib/amdt";
import Api from "@/lib/api";
import ATextField from "@/components/elements/a-text-field.vue";
import AAutocomplete from "@/components/elements/a-autocomplete.vue";
import VForm from "@/lib/types/v-form";
import ABtn from "@/components/elements/a-btn.vue";
import { required } from "@/lib/validations";
import ADate from "@/lib/a-date";
import IPerson from "@/lib/interfaces/person";

export default Vue.extend({
  name: "ReportsRegion",

  components: { ATextField, AAutocomplete, ABtn },

  data() {
    return {
      loading: false,
      ret_id: 1,
      ret_msg: "",

      valid: false,

      regioes: new Array<IPerson>(),

      dataQuery: {
        regiao_id: [],
        data_inicial: new ADate().dateString,
        data_final: new ADate().dateString,
      },
    };
  },

  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },

    query(): {
      regiao_id: string;
      data_inicial: string;
      data_final: string;
    } {
      return {
        ...this.dataQuery,
        regiao_id: this.dataQuery.regiao_id.join(", ") || "-1",
      };
    },

    hint(): string | undefined {
      return this.dataQuery.regiao_id.length > 0 ? undefined : "TODOS";
    },
  },

  mounted() {
    this.getRegioes().then();
  },

  methods: {
    required,

    async evOnSubmit() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const { regiao_id, data_inicial, data_final } = this.query;
        const query = new URLSearchParams();
        query.append("regiao_id", regiao_id);
        query.append("data_inicial", data_inicial);
        query.append("data_final", data_final);

        Api.openWindow(`/historico-regiao?${query.toString()}`);

        this.$emit("updated");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getRegioes() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("pessoas.read", {
          tipo: "EMPRESA",
          desativado: "0",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.regioes = rsp.getTable("pessoas")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
